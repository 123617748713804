#header
{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.link
{
    color: rgb(49, 49, 49);
    font-weight: 300;
    font-family: "16bit";
    font-size: 40px;
    padding: 15px;
    text-decoration: none;
}

.link:hover{
    color: rgb(188, 188, 188);
    transition: color 500ms ease-in-out;
    cursor: pointer;
}

.hamburger{
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    justify-content: space-evenly;
    width: 50px;
    height: 50px;
    border: black 1px solid;
    padding: 3px;
}

.lines{
    width: 100%;
    height: 3px;
    background-color: black;
    transition: 300ms ease-in-out;
}

.lines:nth-child(1){
    width: 50%;
}

.lines:nth-child(2){
    background-color: grey;
    width: 75%;
}

.hamburger:hover > div{
    width: 100%;
}

.header-show{
    position: sticky;
    display: flex;
    z-index: 1;
    justify-content: flex-start;
    flex-direction: column;
    transform: translateX(0);
    background-color: black;
    color: white;
    height: 1000px;
    transition: 200ms;
}

.links-col{
    justify-self: flex-start;
}

.header-hide{
    transform: translateX(-200%);
    transition: 200ms;
    height: 0;
}

.hide{
	display: none !important;
}
