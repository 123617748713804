@font-face {
	font-family: "NTR";
	src: url('./styles/fonts/NTR/NTR-Regular.ttf');
}

@font-face {
  font-family: "Yantramanav";
  src: url('./styles/fonts/Yantramanav/Yantramanav-Regular.ttf');
}

@font-face {
  font-family: "VT323";
  src: url('./styles/fonts/VT323/VT323-Regular.ttf');
}


@font-face {
  font-family: "16bit";
  src: url('./styles/fonts/16bit/16bit.otf');
}

body {
  margin: 0;
  height: 100%;
  background-color: rgb(251, 249, 249);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
