
#about-cntr{
	display: flex;
	margin-top: 5px;
	border-top: 5px solid rgb(222, 222, 222);
}

#about{
	width: 75%;
	display: flex;
	flex-direction: column;
    height: 100%;
	margin-top: 10%;
	margin-left: 10%;
}

#intro{
	font-size: 5rem;
	font-weight: 650;
	font-family: "NTR", sans-serif;
	color: aliceblue;
	line-height: 100px;
}

#blinker{
	display: inline;
	font-size: 6rem;
	letter-spacing: 0;
	font-weight: 500;
	animation: blink 1s infinite;
	
}

#first{
	color: rgb(0, 0, 0);
}

@keyframes blink {
	from, to {
		color: transparent;
	}
	50%{
		color: rgb(149, 149, 149);
	}

}

#about-desc{
	display: inline;
	line-height: 50px;
	font-size: 1.5rem;
	font-weight: 350;
	font-family: "Yantramanav", sans-serif;
	color: rgb(107, 107, 107);
}

#portrait{
	
	width: 100%;
	height: 100vh;
	background-color: rgb(228, 218, 207);
}

#card{
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: rgb(244, 238, 232);
	width: 350px;
	height: 500px;
	top: 150px;
	left: 50px;
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.296);
}

#pic > img{
	border-radius: 50%;
	border: 10px rgb(225, 224, 223) solid;
	box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.296);
	max-width: 50%;
	max-height: 50%;
	display: block;
	margin-left: 22%;
	margin-top: 10%;
}

#card-info{
	display: flex;
	flex-direction: column;
}

.card-name{
	text-align: center;
	font-family: "Yantramanav";
	font-size: 1.5rem;
	padding: 0;
	padding-top: 10px;
}

#divider {
	align-self: center;
	width: 10%;
	height: 5px;
	margin-top: 5%;
	background-color: grey;
}

#position{
	font-family: 'Courier New', Courier, monospace;
	font-weight: 10px;
	text-align: center;
	margin-top: 10%;
}

.show_portrait{
	transition: 300ms;
	transform: translateX(0);
}

.hide_portrait{
	transition: 500ms;
	transform: translateX(-300%);
}

/* .hide{
	display: none;
} */

@media screen and (max-width: 800px){
	#about{
		margin-left: 25%;
		width: 100%;
	}
}






