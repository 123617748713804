.divider{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 500px;
    margin-left: 10px;
    height: 250px;
    width: 20%;
}

.divider > span{
    font-size: 50px;
    font-family: sans-serif;
    font-weight: 500;
    color: rgb(47, 182, 187);;
}

.line{
    height: 3px;
    width: initial;
    
    background-color: rgb(39, 142, 145);;
}