.project-cntr{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 370px;
}

.project-card{
    width: 850px;
    padding: 1px;
    height: 450px;
    box-shadow: 6px 5px 8px 0 rgba(0, 0, 0, 0.38);
    overflow: hidden;
}

.slider{
    transition: transform 500ms ease-out;
}

.project-img{
    display: block;
    position: absolute;
    object-fit: cover;
    height:450px;
    width: 850px;
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.slider:hover > .project-img{
    opacity: 0.2;
}

.title{
    position: absolute;
    font-family: "NTR";
    margin-left: 40%;
    margin-top: 18%;
    font-size: 25px;
}

.project-desc{
    padding: 20px;
    position: absolute;
    font-family: "Yantramanav";
    margin-left: 5%;
    margin-top: 25%;
    font-size: 25px;
}

.card-btn{
    font-size: 30px;
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    top: 200px;
    border: none;
    background: linear-gradient(to right, purple, red);
    z-index: 2;
}

#prev-btn{
    left: 20px;
}
#next-btn{
    left: 740px;
    
}

.card-btn:hover{
    cursor: pointer;
}

@media screen and (max-width: 1024px) {

    .project-card{
        width: 650px;
        
    }

    #next-btn{
        left: 540px;
        
    }

    .project-img{
        opacity: 0.3;
        width: 650px;
    }

}

@media screen and (max-width: 600px) {

    .project-card{
        width: 350px;
        
    }

    #next-btn{
        left: 270px;
        
    }

    .project-img{
        opacity: 0.3;
        width: 350px;
    }

    .card-btn{
        padding: 0;
        font-size: 15px;
        height: 35px;
        width: 30px;
       
    }

    .project-desc{
        padding: 30px;
        font-size: 20px;
    }

    .title{
        margin-left: 15%;
        font-size: 30px;
    }
}