.outline
{
    margin-top: 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.row
{
    position: absolute;
    display: flex;
    align-self: center;
    
}

.row > div{
    margin: 5px;
}
.row1
{
    margin-right: 190px;
}

.row2{
    margin-top: 310px;
}

.row3{
    margin-top: 610px;
    margin-right: 190px;
}

@media screen and (max-width: 1024px) {
    
.row1{
    margin-right: 120px;
}

.row2{
    margin-top: 210px;
}

.row3{
    margin-top: 420px;
    margin-right: 130px;
}
}

@media screen and (max-width: 600px) {
    
    .row1{
        margin-right: 70px;
    }
    
    .row2{
        margin-top: 170px;
        margin-right: -30px;
    }
    
    .row3{
        margin-top: 335px;
        margin-right: 70px;
    }
    }


