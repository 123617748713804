.contact-cntr{
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: "Yantramanav";
}

#my-contact{
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 700px;
    background: linear-gradient(to bottom, rgb(81, 46, 221), rgb(52, 23, 170), rgb(17, 2, 73));
    box-shadow: 0px -7px 15px 5px rgba(0, 0, 0, 0.203);
}

#contact-me{
    font-family: 'NTR';
    width: 50%;
    height: 700px;
    background-color: rgb(255, 255, 255);
    box-shadow: 5px -7px 15px 0px rgba(0, 0, 0, 0.203);
}

#contact-info-title{
    color: white;
    margin-left: 50px;
}


.email-fields{
    display: flex;
    flex-wrap: wrap;
}

.message{
    flex-direction: column;
}

.email-form{
    display: flex;
    flex-direction: column;
    height: 75%;
    padding: 20px;
}

.message-box{
    height: 200px;
    font-size: 20px;
}

.name-fields{
    width: 50%;
    height: 40px;
    border: 0;
    font-size: 20px;
    border-bottom: 1px solid black;
}

label{
    font-size: 18px;
    width: 100%;
}


.submit-email{

    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    align-self: center;
    width: 50%;
    padding: 10px;
    background-color: rgb(109, 66, 251);
    border: none;

}

.door-effect{
    box-shadow: inset -18.5em 0 0 0 rgb(83, 25, 171), inset 18.5em 0 0 0 rgb(83, 25, 171);
}

.submit-email:hover{
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .contact-cntr{
        flex-direction: column;
    }

    #my-contact{
        width: 75%;
        align-self: center;
    }

    #contact-me{
        align-self: center;
        width: 75%;
        height: 650px;
    }
    .email-fields {
        flex-direction: column;
    }
}
