.label-section{
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    margin-top: 20px;
}

.titles{
    font-family: "Yantramanav";
    font-size: 18px;
    color: rgb(164, 162, 162);
}

.values{
    margin-left: 18px;
    font-size: 18px;
    color: white;
}

.icons{
    
    height: 14px;
    width: 18px;
}

