.honey-comb{
    display: flex;
    justify-content: center;
    height: 160px;
    width: 180px;
    background-color: rgb(103, 239, 155);
    transition: 300ms ease-in-out;
    clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.honey-comb-img{
    z-index: 1;
    width: 165px;
    height: 150px;
    align-self: center;
    clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.desc-cntr{
    position: absolute;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 150ms;
    background-color: black;
}

.desc{
    height: 50px;
    width: 80px;
    font-family: "Yantramanav";
    font-size: 50px;
    align-self: center;
    font-weight: 300;
    color: white;
    z-index: 3;
}


.honey-comb:hover{
    background-color: rgb(17, 111, 102);
}

.hide{
    display: none;
}

.show{
    display: flex;
}

.door{
    box-shadow: inset -18.5em 0 0 0 rgb(25, 171, 149), inset 18.5em 0 0 0 rgb(25, 171, 149);
}


@media screen and (max-width: 1024px) {
    .honey-comb{
        height: 100px;
        width: 120px;
    }
    
    .honey-comb-img{
        width: 105px;
        height: 90px;
    }

    .door{
        box-shadow: inset -15.5em 0 0 0 rgb(25, 171, 149), inset 15.5em 0 0 0 rgb(25, 171, 149);
    }
}

@media screen and (max-width: 600px) {
    .honey-comb{
        height: 80px;
        width: 100px;
    }
    
    .honey-comb-img{
        width: 85px;
        height: 70px;
    }

    .door{
        box-shadow: inset -10.5em 0 0 0 rgb(25, 171, 149), inset 10.5em 0 0 0 rgb(25, 171, 149);
    }

    .desc{
        font-size: 40px;
        text-align: center;
    }
}